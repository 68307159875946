<template>
    <v-main>
      <router-view />
    </v-main>
</template>

<script>
export default {
  name: "DashboardCoreView",

  components: {
    //DashboardCoreFooter: () => import('./Footer'),
  },
  computed: {
    theme() {
      return this.$vuetify.theme.dark ? "dark" : "light";
    },
  },
};
</script>

<style>
  .v-main__wrap.v-content__wrap {
     background-color: #bdc3ce !important;
  }
</style>